import React, { useEffect, useState } from "react";
import styles from "./HeroNews.module.css";

import newsLogo from "../../images/news.png";
import { Link } from "react-router-dom";

const HeroNews = ({ emptyNews, moreNews }) => {
  const [news, setNews] = useState([]);
  useEffect(() => {
    fetchNews(setNews, emptyNews, moreNews);
  }, [emptyNews, moreNews]);
  return news.length > 0 ? (
    <div className={styles.news}>
      {news.map((newsArticle) => {
        let image;
        if(!newsArticle.imageUrl || newsArticle.imageUrl.endsWith(".pdf")){
          image = newsLogo;
        }else{
          image = newsArticle.imageUrl;
        }
        return (
          <div key={newsArticle.id} className={styles["news-article"]}>
            {newsArticle.hasContent ? (
              <Link to={`/news/${newsArticle.id}`}>
                <div className={styles["news-thumb"]}>
                  <span>{newsArticle.title}</span>
                  <div className={styles["image-div"]}>
                    {image? <img src={image} alt="imagem da notícia"></img>: null}
                  </div>
                </div>
              </Link>
            ) : (
              <a
                href={newsArticle.imageUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles["news-thumb"]}>
                  <span>{newsArticle.title}</span>
                  <div className={styles["image-div"]}>
                    <img src={image} alt="imagem da notícia"></img>
                  </div>
                </div>
              </a>
            )}
          </div>
        );
      })}
    </div>
  ) : null;
};

export default HeroNews;

function fetchNews(setNews, emptyNews, moreNews) {
  fetch(
    "https://cdn.contentful.com/spaces/s5x4y3hpltus/environments/master/entries?limit=3",
    {
      headers: new Headers({
        Authorization: "Bearer ydc6rqQj6SPifLCFaD700obs1bm0rmMuN19-kbAdTD8",
      }),
    }
  )
    .then((data) => data.json())
    .then((data) => {
      if (!data.errors) {
        if (data.items.length > 1) {
          moreNews(true);
        } else {
          moreNews(false);
        }
        let imageUrls;
        if (data.includes) {
          imageUrls = data.includes.Asset.map((asset) => asset.fields.file.url);
        }
        setNews(
          data.items.map((item) => {
            const assetIds = getAssetIdsFromNewsArticle(item);
            const imageId = item.fields.image
              ? item.fields.image.sys.id
              : assetIds[0];
            const imageUrl = imageUrls.find(
              (url) =>
                url.startsWith(
                  "//images.ctfassets.net/s5x4y3hpltus/" + imageId
                ) ||
                url.startsWith("//assets.ctfassets.net/s5x4y3hpltus/" + imageId)
            );
            return {
              id: item.sys.id,
              title: item.fields.title,
              content: item.fields.content,
              imageUrl,
              createdAt: item.sys.createdAt,
              hasContent: item.fields.content.content.some(
                (c) =>
                  c.nodeType === "paragraph" &&
                  c.content.some(
                    (nT) => nT.nodeType === "text" && nT.value.trim().length > 0
                  )
              ),
            };
          })
        );
        if (data.items.length === 0) {
          emptyNews(true);
        } else {
          emptyNews(false);
        }
      }
    });
}

function getAssetIdsFromNewsArticle(article) {
  const assets = [];
  const content = article.fields.content.content;
  for (let c of content) {
    if (c.data && c.data.target) {
      assets.push(c.data.target.sys.id);
    }
  }
  return assets;
}
