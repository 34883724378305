import React from "react";
import styles from "./Services.module.css";
import { useTranslation } from "react-i18next";
const Services = () => {
  const { t } = useTranslation("services");
  return (
    <div className={styles.servicos}>
      <h3>{t("title")}:</h3>
      <ul>
        <li>{t("service1")}</li>
        <li>{t("service2")}</li>
        <li>{t("service3")}</li>
        <li>{t("service4")}</li>
        <li>{t("service5")}</li>
        <li>{t("service6")}</li>
      </ul>
      <h3>{t("other-services")}:</h3>
      <ul>
        <li>{t("service7")}</li>
        <li>{t("service8")}</li>
      </ul>
      <div className="cta">
        {t("estimate")}
        <br />
        {t("call")}
        <span> {t("phone-number")}</span>
      </div>
    </div>
  );
};

export default Services;
