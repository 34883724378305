import React, { useEffect, useState } from "react";
import styles from "./Hero.module.css";
import arrows from "../../images/arrows.png";
import HeroNews from "../HeroNews/HeroNews";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Hero = () => {
  const { t } = useTranslation("hero");
  const [loaded, setLoaded] = useState(false);
  const [isEmptyNews, setEmptyNews] = useState(true);
  const [hasMoreNews, setMoreNews] = useState(false);
  useEffect(() => {
    setLoaded(true);
  }, [loaded, isEmptyNews]);

  function showNews(loaded) {
    return (
      <div
        className={[
          styles.news,
          loaded && !isEmptyNews ? styles.show : "",
        ].join(" ")}
      >
        <div className={styles.destaque}>
          <span>{t("featuring")}:</span>
          {hasMoreNews ? <Link to="/news">{t("see-more")}</Link> : null}
        </div>
        <HeroNews emptyNews={setEmptyNews} moreNews={setMoreNews}></HeroNews>
      </div>
    );
  }

  return (
    <div className={styles.hero}>
      <div className={styles.left}>
        <div>
          {t("more-than")} <br />
          <span>20</span> {t("years")} <br />
          {t("experience")} <br />
          {t("at-your-service")}.
        </div>
      </div>
      {showNews(loaded)}
      <div
        className={styles["saiba-mais"]}
        onClick={() => {
          window.scrollTo({
            top: 650,
            behavior: "smooth",
          });
        }}
      >
        {t("know-more")}
        <img src={arrows} alt="saiba mais" />
      </div>
    </div>
  );
};

export default Hero;
