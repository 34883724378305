import React from "react";
import logo from "../../images/logo.png";
import ukFlag from "../../images/uk-flag.png";
import portugalFlag from "../../images/portugal-flag.png";
import styles from "./Header.module.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const Header = () => {
  const { i18n } = useTranslation("header");
  return (
    <div className={styles.header}>
      <Link to="/">
        <img
          className={styles["brand-logo"]}
          src={logo}
          alt="VBContabilidade logótipo"
        ></img>
      </Link>
      <div className={styles.right}>
        <div className="info">
          <p>210 808 065</p>
          <p>Avenida da Liberdade, Lote 6 - Loja C - Palmela</p>
        </div>
        <div className={styles.language}>
          <p>{i18n.language === "en" ? "Português" : "English"}?</p>
          <img
            onClick={() =>
              i18n.changeLanguage(i18n.language === "en" ? "pt" : "en")
            }
            alt="bandeira-uk"
            src={i18n.language === "en" ? portugalFlag : ukFlag}
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
