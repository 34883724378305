import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import HomePage from "./components/Pages/HomePage";
import NewsArticle from "./components/Pages/NewsArticle";
import News from "./components/Pages/News";

const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={HomePage}></Route>
        <Route exact path="/news" component={News}></Route>
        <Route path="/news/:id" component={NewsArticle}></Route>
      </Switch>
    </Router>
  );
};

export default App;
