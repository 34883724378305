import React from "react";
import styles from "./AboutUs.module.css";
import fotoPerfil from "../../images/perfil.png";
import { useTranslation } from "react-i18next";

const AboutUs = () => {
  const { t } = useTranslation("aboutUs");
  return (
    <div className={styles["sobre-nos"]}>
      <h3>{t("title")}:</h3>
      <div className={styles.profile}>
        <img className={styles.photo} src={fotoPerfil} alt="foto de perfil" />
        <div className={styles["photo-description"]}>
          {t("photo-description.part1")} <br /> {t("photo-description.part2")}
          <br />
          <span>{t("photo-description.years")}</span>{" "}
          {t("photo-description.part3")}.
        </div>
      </div>
      <div className={styles["statement-and-clients"]}>
        <div className={styles.statement}>
          <p>{t("mission")}</p>
          <p>{t("vision")}</p>
          <p>{t("strategy")}</p>
        </div>
        <div className={styles.clients}>
          {t("clients.intro")}:
          <ul>
            <li>{t("clients.commerce")}</li>
            <li>{t("clients.construction")}</li>
            <li>{t("clients.food-distribution")}</li>
            <li>{t("clients.local-renting")}</li>
            <li>{t("clients.real-estate")}</li>
            <li>{t("clients.translation")}</li>
            <li>{t("clients.medical-services")}</li>
            <li>{t("clients.turism")}</li>
            <li>{t("clients.transportation-of-goods")}</li>
            <li>{t("clients.transportation-of-people")}</li>
            <li>{t("clients.restaurant")}</li>
            <li>{t("clients.services")}</li>
            <li>{t("clients.other")}...</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
