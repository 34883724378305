import React from "react";
import Layout from "../Layout/Layout";
import Services from "../Services/Services";
import Contacts from "../Contacts/Contacts";
import AboutUs from "../AboutUs/AboutUs";
import Hero from "../Hero/Hero";

const HomePage = () => {
  return (
    <Layout>
      <Hero></Hero>
      <Services></Services>
      <AboutUs></AboutUs>
      <Contacts></Contacts>
    </Layout>
  );
};

export default HomePage;
