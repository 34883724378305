import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import aboutUs_en from "./translations/en/about-us.json";
import common_en from "./translations/en/common.json";
import services_en from "./translations/en/services.json";
import footer_en from "./translations/en/footer.json";
import contacts_en from "./translations/en/contacts.json";
import hero_en from "./translations/en/hero.json";
import aboutUs_pt from "./translations/pt/about-us.json";
import common_pt from "./translations/pt/common.json";
import services_pt from "./translations/pt/services.json";
import footer_pt from "./translations/pt/footer.json";
import contacts_pt from "./translations/pt/contacts.json";
import hero_pt from "./translations/pt/hero.json";

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: "pt",
  resources: {
    en: {
      common: common_en,
      aboutUs: aboutUs_en,
      contacts: contacts_en,
      footer: footer_en,
      services: services_en,
      hero: hero_en,
    },
    pt: {
      common: common_pt,
      aboutUs: aboutUs_pt,
      contacts: contacts_pt,
      footer: footer_pt,
      services: services_pt,
      hero: hero_pt,
    },
  },
});
ReactDOM.render(
  <I18nextProvider i18n={i18next}>
    <App></App>
  </I18nextProvider>,
  document.getElementById("root")
);
