import React from "react";
import styles from "./Contacts.module.css";
import { useTranslation } from "react-i18next";
const Contacts = () => {
  const { t } = useTranslation("contacts");
  return (
    <>
      <h3 className={styles.title}>{t("title")}:</h3>
      <div className={styles.contacts}>
        <div className={styles["contact-info"]}>
          <div className="address">
            Avª Liberdade Lote 6 - Loja C<br />
            2950-201 Palmela
          </div>
          <div className="schedule">
            <strong>{t("schedule")}:</strong>
            <br /> {t("days-of-week")},
            <br /> {t("time")}.
          </div>
          <div className="email">vbconta@gmail.com</div>
          <div className="telephone">{t("phone")}: 210 808 065</div>
        </div>
        <p className={styles["mobile-link"]}>
          {" "}
          {t("click")}{" "}
          <a href="https://goo.gl/maps/GNVHMndNVncApB8F9">{t("here")}</a>
          &nbsp;{t("to-see-location")}
        </p>
        <iframe
          title="Onde estamos"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12477.021606276001!2d-8.9026368!3d38.573966!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd19415d63d2becd%3A0xd679f9e667ea6db0!2sAv.%20da%20Liberdade%206%2C%202950-201%20Palmela%2C%20Portugal!5e0!3m2!1snl!2sbe!4v1580114384686!5m2!1snl!2sbe"
          allowFullScreen=""
          width="600"
          height="450"
          frameBorder="0"
        ></iframe>
      </div>
    </>
  );
};

export default Contacts;
