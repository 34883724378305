import React from "react";
import styles from "./Footer.module.css";

import ambienteDesinfetado from "../../images/Ambiente desinfetado.gif";
import facebook from "../../images/facebook.png";
import occ from "../../images/occ_logo.png";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation("footer");
  return (
    <footer className={styles.footer}>
      <div className="left">
        <p className="brand-name">VBContabilidade</p>
        <p className="call-to-action">
          {t("estimate")}, <span>{t("call")}</span>
        </p>
      </div>
      <div className="text-left flex">
        <img
          className="ambiente-desinfetado"
          src={ambienteDesinfetado}
          alt="ambiente desinfetado"
        />
        <img className="occ-logo" src={occ} alt="occ-logotipo" />
        <p className="facebook">
          {t("follow-fb")}
          <a
            href="https://www.facebook.com/pg/vbconta/posts/?ref=page_internal"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className={styles.facebook}
              src={facebook}
              alt="facebook-link"
            />
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
