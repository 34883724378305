import React, { useState, useEffect } from "react";

import { BLOCKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Layout from "../Layout/Layout";
import styles from "./NewsArticle.module.css";
import newsLogo from "../../images/news.png";
import { Link } from "react-router-dom";

const NewsArticle = ({ match }) => {
  const [newsArticle, setNewsArticle] = useState();
  const [assets, setAssets] = useState([]);
  useEffect(() => {
    fetchNewsArticle(setNewsArticle, match.params.id);
  }, [match.params.id]);

  async function fetchAsset(id) {
    try {
      let response = await fetch(
        `   https://cdn.contentful.com/spaces/s5x4y3hpltus/environments/master/assets/${id}`,
        {
          headers: new Headers({
            Authorization: "Bearer ydc6rqQj6SPifLCFaD700obs1bm0rmMuN19-kbAdTD8",
          }),
        }
      );
      let asset = await response.json();
      return asset;
    } catch (error) {
      throw error;
    }
  }

  function findAllEmbeddedAssets(entry) {
    entry.fields.content.content
      .filter((node) => node.nodeType === "embedded-asset-block")
      .map(async (node) => {
        const asset = await fetchAsset(node.data.target.sys.id);
        setAssets({
          ...assets,
          [node.data.target.sys.id]: asset.fields.file.url,
        });
      });
  }

  async function fetchNewsArticle(setNewsArticle, id) {
    let response = await fetch(
      `https://cdn.contentful.com/spaces/s5x4y3hpltus/environments/master/entries/${id}`,
      {
        headers: new Headers({
          Authorization: "Bearer ydc6rqQj6SPifLCFaD700obs1bm0rmMuN19-kbAdTD8",
        }),
      }
    );
    let entry = await response.json();
    findAllEmbeddedAssets(entry);
    setNewsArticle({
      id: entry.sys.id,
      title: entry.fields.title,
      content: entry.fields.content,
      imageUrl: entry.fields.image,
      createdAt: entry.sys.createdAt,
      hasContent: entry.fields.content.content.some(
        (c) =>
          c.nodeType === "paragraph" &&
          c.content.some(
            (nT) => nT.nodeType === "text" && nT.value.trim().length > 0
          )
      ),
    });
  }

  let image;
  if (!newsArticle || !newsArticle.imageUrl) {
    image = "";
  } else if (newsArticle.imageUrl.endsWith(".pdf")) {
    image = newsLogo;
  } else {
    image = newsArticle.imageUrl;
  }

  return newsArticle && assets ? (
    <Layout>
      <div className={styles.main}>
        <Link to="/">&lt; Voltar à página inicial</Link>

        <div className={styles["news-article"]}>
          <h5 className={styles.title}>{newsArticle.title}</h5>
          <div className={styles["news-article-content"]}>
            {image ? (
              <a href={newsArticle.imageUrl}>
                <img src={image} alt="article"></img>
              </a>
            ) : null}
            <div className={styles["rich-text"]}>
              {documentToReactComponents(newsArticle.content, {
                renderNode: {
                  [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
                    return <embed src={assets[node.data.target.sys.id]} width="80%" height="2100px" />;
                  },
                },
              })}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  ) : null;
};

export default NewsArticle;
