import React, { useEffect, useState } from "react";
import styles from "./News.module.css";
import Layout from "../Layout/Layout";
import pdfLogo from "../../images/pdf.png";
import newsLogo from "../../images/news.png";
import { Link } from "react-router-dom";

const News = () => {
  const [news, setNews] = useState([]);
  useEffect(() => {
    fetchNews(setNews);
  }, []);

  function getImageUrl(newsArticle) {
    if (newsArticle && newsArticle.imageUrl) {
      return newsArticle.imageUrl.endsWith(".pdf") ? pdfLogo : newsArticle.imageUrl;
    } else {
      return newsLogo;
    }
  }

  return (
    <Layout>
      <div className={styles.news}>
        <Link to="/">&lt; Voltar à página inicial</Link>
        <p className={styles.title}>Notícias</p>
        <div className={styles["news-list"]}>
          {news.map((newsArticle) => {
            const image = getImageUrl(newsArticle);
            return (
              <div key={newsArticle.id} className={styles["news-article"]}>
                {newsArticle.hasContent ? (
                  <Link to={`/news/${newsArticle.id}`}>
                    <div className={styles["news-thumb"]}>
                      <div className={styles["image-div"]}>
                        <img src={image} alt="imagem da notícia"></img>
                      </div>
                      <span>{newsArticle.title}</span>
                    </div>
                  </Link>
                ) : (
                  <a href={newsArticle.imageUrl} target="_blank" rel="noopener noreferrer">
                    <div className={styles["news-thumb"]}>
                      <div className={styles["image-div"]}>
                        <img src={image} alt="imagem da notícia"></img>
                      </div>
                      <span>{newsArticle.title}</span>
                    </div>
                  </a>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </Layout>
  );
};

export default News;

function fetchNews(setNews) {
  fetch(
    "https://cdn.contentful.com/spaces/s5x4y3hpltus/environments/master/entries?limit=100",
    {
      headers: new Headers({
        Authorization: "Bearer ydc6rqQj6SPifLCFaD700obs1bm0rmMuN19-kbAdTD8",
      }),
    }
  )
    .then((data) => data.json())
    .then((data) => {
      if (!data.errors) {
        const imageUrls = data.includes.Asset.map(
          (asset) => asset.fields.file.url
        );
        setNews(
          data.items.map((item) => {
            const assetIds = getAssetIdsFromNewsArticle(item);
            if (assetIds.length > 0) {
              const imageId = item.fields.file
                ? item.fields.file.sys.id
                : assetIds[0];
              const imageUrl = imageUrls.find(
                (url) =>
                  url.startsWith(
                    "//images.ctfassets.net/s5x4y3hpltus/" + imageId
                  ) ||
                  url.startsWith(
                    "//assets.ctfassets.net/s5x4y3hpltus/" + imageId
                  )
              );
              return {
                id: item.sys.id,
                title: item.fields.title,
                content: item.fields.content,
                imageUrl,
                createdAt: item.sys.createdAt,
                hasContent: item.fields.content.content.some(
                  (c) =>
                    c.nodeType === "paragraph" &&
                    c.content.some(
                      (nT) =>
                        nT.nodeType === "text" && nT.value.trim().length > 0
                    )
                ),
              };
            } else {
              return {
                id: item.sys.id,
                title: item.fields.title,
                content: item.fields.content,
                imageUrl: null,
                createdAt: item.sys.createdAt,
                hasContent: item.fields.content.content.some(
                  (c) =>
                    c.nodeType === "paragraph" &&
                    c.content.some(
                      (nT) =>
                        nT.nodeType === "text" && nT.value.trim().length > 0
                    )
                ),
              };
            }
          })
        );
      }
    });
}

function getAssetIdsFromNewsArticle(article) {
  const assets = [];
  const content = article.fields.content.content;
  for (let c of content) {
    if (c.data && c.data.target) {
      assets.push(c.data.target.sys.id);
    }
  }
  return assets;
}
